import React from "react";
import "./Style.css";

const getWeekNumber = (d) => {
  d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
  d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
  var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
  return weekNo;
};

const Home = () => {
  const today = new Date();
  const weekNumber = getWeekNumber(today);
  const dayOfWeek = today.getDay(); // 0 is Sunday, 1 is Monday, etc.

  let sluzbyKnihovna = {
    sudyTyden: {
      Pondeli: ["Benešová", "Mít"],
      Utery: ["Afzaal"],
      Streda: ["Miss Lojčis"],
      Ctvrtek: ["Benešová", "Mít", "Sýkorová"],
      Patek: ["Sýkorová", "Afzaal"],
    },
    lichyTyden: {
      Pondeli: ["Vondříčková", "Beneš"],
      Utery: ["Benešová", "Afzaal"],
      Streda: ["Benešová", "Beneš"],
      Ctvrtek: ["Mít"],
      Patek: ["Mít", "Vondříčková"],
    },
  };

  const days = [
    "Nedele",
    "Pondeli",
    "Utery",
    "Streda",
    "Ctvrtek",
    "Patek",
    "Sobota",
  ];
  const currentDay = days[dayOfWeek];
  const isEvenWeek = weekNumber % 2 === 0;
  const currentServices = isEvenWeek
    ? sluzbyKnihovna.sudyTyden[currentDay]
    : sluzbyKnihovna.lichyTyden[currentDay];

  return (
    <div className="home">
        {/*isEvenWeek ? <p>Sudý týden</p> : <p>Lichý týden</p>*/}
      <div className="home-block">
        <p>
          Dnes má službu v knihovně:{" "}
          </p>
          <b>
          {currentServices ? currentServices.join(", ") : "Žádné služby"}
          </b>
      </div>
    </div>
  );
};

export default Home;
